<template>
  <div id="LoginSign">
    <div class="content">
      <div class="middle-container">
        <!-- <h3>校长信箱管理系统登录</h3> -->
        <div class="container">
          <LoginPageVue />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginPageVue from './LoginPage.vue';
export default {
  name: 'LoginSign',
  components: {
    LoginPageVue
  }
};
</script>

<style lang="scss" scoped>
$color: rgb(253 76 93);
.active {
  border-bottom: 2px solid #23a9f2 !important;
  color: #23a9f2 !important;
}
.content {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  //   background-image: url("../assets/envelope.webp");
  .middle-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 10px;
    padding: 0 20px;
    width: 100%;
    max-width: 100%;
    height: 522px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    h3 {
      margin-top: 20px;
      font-family: stencil;
      text-align: center;
      color: #23a9f2;
    }
    .container {
      position: relative;
      margin-top: 20px;
      width: 100%;
      height: 420px;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: 8px 8px 48px rgb(0 0 0 / 30%);
      .heard {
        display: flex;
        padding: 0 20px;
        width: 100%;
        height: 40px;
        flex-direction: row;
        .login {
          display: block;
          width: 100%;
          height: 100%;
          font-weight: 900;
          border-bottom: 2px solid #ffffff;
          text-align: center;
          text-decoration: none;
          color: black;
          transition: all 0.3s;
          cursor: pointer;
          line-height: 40px;
        }
        .line {
          color: #23a9f2;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
